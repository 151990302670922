"use client";

import { useState, useRef, useEffect, useCallback } from "react";

import { Play, Pause } from "lucide-react";

import { Button } from "./button";
import { Slider } from "./slider";

function AudioPlayer({ src }: { src: string }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const setAudioData = () => {
      if (audio.duration === Infinity) {
        audio.currentTime = 100000;
        setTimeout(() => {
          setDuration(audio.duration);
          setCurrentTime(audio.currentTime);
        }, 100);
      } else {
        setDuration(audio.duration);
        setCurrentTime(audio.currentTime);
      }
    };

    const handleTimeUpdate = () => {
      // Only update the slider if we're not currently dragging
      if (!isDragging) {
        setCurrentTime(audio.currentTime);
      }
    };

    // Add event listeners
    audio.addEventListener("loadeddata", setAudioData);
    audio.addEventListener("timeupdate", handleTimeUpdate);

    // Remove event listeners on cleanup
    return () => {
      audio.removeEventListener("loadeddata", setAudioData);
      audio.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, [isDragging]);

  const togglePlayPause = () => {
    const audio = audioRef.current;
    if (!audio) return;

    if (isPlaying) {
      audio.pause();
    } else {
      void audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleSliderChange = useCallback(
    (value: number[]) => {
      setIsDragging(true);
      const audio = audioRef.current;
      if (!audio) return;

      if (isPlaying) {
        audio.pause();
        setIsPlaying(false);
      }

      const percentage = (value[0] ?? 1) / 100;
      const newTime = duration * percentage;

      setCurrentTime(newTime);
    },
    [duration, isPlaying]
  );

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  return (
    <div className="w-full rounded-lg">
      <audio ref={audioRef} src={src} />
      <div className="mb-4 flex items-center justify-between">
        <Button
          onClick={togglePlayPause}
          variant="outline"
          size="icon"
          aria-label={isPlaying ? "Pause" : "Play"}
        >
          {isPlaying ? (
            <Pause className="h-4 w-4" />
          ) : (
            <Play className="h-4 w-4" />
          )}
        </Button>
        {duration !== Infinity && duration !== 0 && (
          <div className="text-sm font-medium">
            {formatTime(currentTime)} / {formatTime(duration)}
          </div>
        )}
      </div>
      <Slider
        value={[(currentTime / duration) * 100]}
        max={100}
        min={0}
        step={5}
        onValueChange={(value: number[]) => {
          handleSliderChange(value);
        }}
        onValueCommit={async (value: number[]) => {
          const audio = audioRef.current;
          if (!audio) return;

          const percentage = (value[0] ?? 1) / 100;
          const newTime = duration * percentage;

          audio.currentTime = newTime;
          setCurrentTime(newTime);
          setIsDragging(false);

          if (!isPlaying) {
            setIsPlaying((prev) => {
              void audio.play();
              return !prev;
            });
          }
        }}
        aria-label="Seek time"
      />
    </div>
  );
}

export { AudioPlayer };
