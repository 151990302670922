"use client";

import * as React from "react";

import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { Loader2 } from "lucide-react";
import Link from "next/link";

import { cn } from "../libs/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary bg-zinc-300 text-secondary-foreground text-zinc-600 hover:bg-secondary/80",
        tertiary:
          "bg-white text-black shadow-[rgba(0,0,0,0.20)_0px_0px_2px_0px] hover:bg-zinc-100",
        ghost:
          "bg-accent/60 text-black hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

interface ButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "variant">,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
  preIcon?: React.ReactNode;
  postIcon?: React.ReactNode;
  path?: string;
  target?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      loading = false,
      children,
      preIcon,
      postIcon,
      path,
      target,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : "button";
    const buttonElement = (
      <Comp
        className={cn("relative", buttonVariants({ variant, size, className }))}
        ref={ref}
        disabled={loading || props.disabled}
        {...props}
      >
        {preIcon && (
          <div
            className={cn("mr-2", {
              "opacity-0": loading,
            })}
          >
            {preIcon}
          </div>
        )}
        {loading ? (
          <div className="inline-flex w-full items-center whitespace-nowrap opacity-0">
            {children}
          </div>
        ) : (
          children
        )}
        {postIcon && (
          <div
            className={cn("ml-2", {
              "opacity-0": loading,
            })}
          >
            {postIcon}
          </div>
        )}
        {loading && (
          <div className="absolute inset-0 z-20 flex items-center justify-center">
            <Loader2 className="h-4 w-4 animate-spin" />
          </div>
        )}
      </Comp>
    );
    if (path) {
      return (
        <Link href={path} target={target}>
          {buttonElement}
        </Link>
      );
    }
    return buttonElement;
  }
);
Button.displayName = "Button";

export { Button, buttonVariants, type ButtonProps };
